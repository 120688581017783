<template>
  <select v-model="model" :class="{'text-boulder': !model, 'block w-full': block}"
          class="custom-select border text-sm rounded-lg p-2.5" v-bind="$attrs">
    <option class="text-boulder" disabled selected value>{{ placeholder }}</option>
    <option
        v-for="(item, index) in options"
        :key="`select-item-${index}`"
        :value="returnObject ? item : item[optionValue]"
    >
      {{ item[optionName] }}
    </option>
  </select>
</template>

<script setup>
import {useIsEmpty} from "@/composables/useIsEmpty";

const {isEmpty} = useIsEmpty()

const props = defineProps({
  modelValue: {
    type: [String, Number, Object],
    default: ""
  },
  placeholder: {
    type: String,
    default: "Please select option"
  },
  optionName: {
    type: String,
    default: "name"
  },
  optionValue: {
    type: String,
    default: "value"
  },
  returnObject: {
    type: Boolean,
    default: false
  },
  options: {
    type: Array,
    default: () => []
  },
  block: {
    type: Boolean,
    default: true
  }
});

const model = computed({
  get() {
    return isEmpty(props.modelValue) ? "" : props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  }
})

const emit = defineEmits(["update:modelValue"]);
</script>

<style>
.custom-select {
  background-image: url("/images/dropdown.svg");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.8em 1.8em;
  padding-right: 2.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
}

/* Override Firefox default style */
.custom-select::-moz-focus-inner {
  border: 0;
}
</style>